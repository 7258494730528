import './Services.scss';

import { Link } from 'gatsby';
import React from 'react';

import PigbankIcon from '../../../icons/pigbank-icon.svg';
import MoneyIcon from '../../../icons/money-icon.svg';
import PaperPenIcon from '../../../icons/paper-pen-icon.svg';
import HeartHandIcon from '../../../icons/heart-hand-icon.svg';
import CobraIcon from '../../../icons/cobra-icon.svg';
import HandDollarIcon from '../../../icons/hand-dollar-icon.svg';
import ReimbursementIcon from '../../../icons/reimbursement-icon.svg';
import LoaIcon from '../../../icons/user-clock-solid.svg';


const serviceTeasers = [
  {
    to: '/services/on-leave',
    icon: LoaIcon,
    name: <span>On Leave</span>,
    description: 'Customized billing and administration services for employees who are on leave.',
    action: 'Read More'
  },
  {
    to: '/services/cobra-administration',
    icon: CobraIcon,
    name: <span>COBRA <br />Administration</span>,
    description: 'Streamlined administration, participant communication and plan reporting for unlimited COBRA and HIPAA needs.',
    action: 'Read More'
  },
  {
    to: '/services/enrollment-benefit-admin',
    icon: PaperPenIcon,
    name: <span>Online Enrollment & <br />Benefit Admin Services</span>,
    description: 'Seamlessly integrated, online enrollment for unlimited benefit plans across multiple insurers and payroll processors.',
    action: 'Read More'
  },
  {
    to: '/services/flexible-spending-accounts',
    icon: MoneyIcon,
    name: <span>Flexible Spending <br />Accounts (FSA)</span>,
    description: 'Simple, intuitive website & FSA debit cards let participants easily track, manage & access FSA funds.',
    action: 'Read More'
  },
  {
    to: '/services/health-savings-accounts',
    icon: PigbankIcon,
    name: <span>Health Savings <br />Accounts (HSA)</span>,
    description: 'Offered to employees enrolled in a high-deductible health plan, an HSA lets employees set aside money on a pre-tax basis to pay for qualified medical, dental, and vision expenses.',
    action: 'Read More'
  },
  {
    to: '/services/health-reimbursement-arrangements',
    icon: HandDollarIcon,
    name: <span>Health Reimbursement <br />Arrangements (HRA)</span>,
    description: 'Simplified plan administration and employee education for any stand-alone or integrated HRA plan.',
    action: 'Read More'
  },
  {
    to: '/services/qsehra',
    icon: ReimbursementIcon,
    name: <span>Qualified Small Employer <br/>Health Reimbursement <br/>Arrangement (QSEHRA)</span>,
    description: 'Reimburse employee-owned premiums and medical expenses, tax-free, for employees of small employers (fewer than 50 full-time-equivalent employees).',
    action: 'Read More'
  },
  {
    to: '/services/retiree-billing',
    icon: HeartHandIcon,
    name: <span>Retiree <br />Billing</span>,
    description: 'Customized billing and administration services for Retiree benefit plans.',
    action: 'Read More'
  }
];

function Services() {


  return (
    <section className="services">

      <ul className="services-list">
        {serviceTeasers.map((service, i) => (
          <li key={`service-${i}`} className="services-list__item">
            <Link to={service.to} className="service-teaser">
              {<service.icon className="service-teaser__icon" />}
              <div className="service-teaser__main">
                <div className="service-teaser__name">{service.name}</div>
                <div className="service-teaser__description">{service.description}</div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
}

export default Services;
