import './Advantage.scss';

import React from 'react';

import LifesaverIcon from '../../../icons/lifesaver-icon.svg';
import Phone24Icon from '../../../icons/phone-24-icon.svg';
import SectionTitle from '../../SectionTitle';
import SupportIcon from '../../../icons/support-icon.svg';

const features = [
  {
    icon: LifesaverIcon,
    name: 'Real Service',
    description: 'Comprehensive & customized employer solutions. Simple & understandable participant access'
  },
  {
    icon: SupportIcon,
    name: 'Real People',
    description: 'Live customer service representatives ready to explain, educate or problem solve.'
  },
  {
    icon: Phone24Icon,
    name: 'Real Time',
    description: 'Phone calls answered within 20 seconds on average, plus 24/7 online portal.'
  }
];

function Advantage() {
  return (
    <section className="advantage">
      <div className="advantage__main">
        <SectionTitle title="What Sets Us Apart" />
        <hr className="advantage-hr" />
        <ul className="advantage-list">
          <li>We answer the phones.
            <ul className="list">
              <li>Average time to answer is &lt;20 seconds</li>
              <li>Average abandonment rate is &lt; 4%</li>
            </ul>
          </li>

          <li>We are flexible.
            <ul>
              <li>“What do you want it to be”  or “Draw us a picture” are usually the answer to most  questions</li>
              <li>Our proprietary software allows us to customize to meet clients unique benefit requirements</li>
            </ul>
          </li>

          <li>We are experienced.
            <ul>
              <li>Our management team has over 100 years of benefit industry experience</li>
            </ul>
          </li>
        </ul>

        <p className="advantage-message">Tri-Star Systems has real people delivering real service in real time to make it easier to fully understand, manage and access every benefit plan.</p>

        <ul className="advantage-features">
          {features.map((feature, i) => (
            <li key={`advantage-feature-${i}`} className="advantage-feature">
              <div className="advantage-feature__icon">
                <feature.icon />
              </div>
              <div className="advantage-feature__main">
                <h2 className="advantage-feature__name">{feature.name}</h2>
                <p className="advantage-feature__description">{feature.description}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="advantage-image"></div>
    </section>
  )
}



export default Advantage;
