import './Hero.scss';

import React from 'react';

function Hero() {
  return (
    <section className="hero">
      <div className="hero-billboard">
        <div className="hero-billboard__main">
          <div className="hero-billboard__message">Our mission is to be the most flexible and innovative solution for your employee benefit administration needs.  Our proprietary software has allowed us to be responsive and adaptable, tailoring our services to meet client needs since 1987.</div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
