import './SectionTitle.scss';

import React from 'react';

function SectionTitle({title, subtext, isCentered, className, ...rest}) {
  let classes = `section-title${className ? ` ${className}` : ''}`;

  if(isCentered) {
    classes = `${classes} section-title--centered`;
  }

  const subtextComp = subtext ? <div className="section-title__subtext">{subtext}</div> : null;
  return (
    <h1 className={classes}>{title}{subtextComp}</h1>
  );
}

export default SectionTitle;
