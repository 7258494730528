import React from 'react'

import { Hero, Services, Advantage } from '../components/Home';
import Layout from '../components/Layout';
import SEO from '../components/SEO';


const IndexPage = () => (
  <Layout>
    <SEO title='' />
    <Hero />
    <Services />
    <Advantage />
  </Layout>
);

export default IndexPage
